import React from 'react';
import { NavLink } from 'react-router-dom';
import { NavItem } from 'reactstrap';

function HeaderButtons ({items}){

    const list = items.map((item) => {
        if(item.disable){
            return(
                <NavItem key={item.id} className={"nav-link center-text disabled"}>
                        <span className={"sublabel fa "+item.icon+" fa-lg"}></span>
                        {item.label}
                </NavItem>
            );}
        else{
            return(
                <NavItem key={item.id}>
                    <NavLink className={"nav-link center-text"} to={item.link}>
                        <span className={"sublabel fa "+item.icon+" fa-lg"}></span>
                        {item.label}
                    </NavLink>
                </NavItem>
            );
        }
    });

    return(
    <React.Fragment>
    {list}
    </React.Fragment>
    );
}

export default HeaderButtons;