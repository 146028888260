import React from 'react';

function Footer({content}) {
	var last = content[content.length-1]
	// console.log(content[content.length-1],content.length,last)
	const text = content.map((c) => {
		var joiner = c.id !== last.id ? ' | ' : "";
		var t = c.label
		if ("link" in c){
			return (
        		<p key={c.id} className="medium"><a href={c.link}>&nbsp;{t}</a>{joiner}</p>
        	);
		}
        return (
        	<p key={c.id} className="medium">&nbsp;{t}{joiner}</p>
        );
     });
    return(
	    <div className="footer">
	        <div className="container-fluid">
	            <div className="row justify-content-center">             
	            	{text}
	            </div>
	        </div>
	    </div>
    )
}

export default Footer;