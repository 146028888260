import React, { Component } from 'react';
import './stylesheets/header.css';
import './stylesheets/sidebar.css';
import './stylesheets/user.css';
import './stylesheets/transitions.css';
import './stylesheets/footer.css';
import './stylesheets/App.css';
import './stylesheets/tables.css';
import './stylesheets/font.css';
import './stylesheets/hyperlinks.css';
import './stylesheets/buttons.css';
// import './stylesheets/newsBox.css';

import Main from './components/MainComponent'
import { BrowserRouter } from 'react-router-dom';
import { Provider} from 'react-redux';
import { ConfigureStore } from './redux/configureStore';

const store = ConfigureStore();


class App extends Component {



  render() {
    return (
      <Provider store={store}>
        <BrowserRouter basename={'/app'}>
          <div className="App">
            <Main />
          </div>
         </BrowserRouter>
        </Provider>
    );
  }
}

export default App;
