import React, { Component } from 'react';

import Browser from './home/Browser.jsx';
import NavBarSide from './navbars/NavBarSide';
import NavBarSubHeader from './navbars/SubHeader';
import NavBarTop from './navbars/NavBarTop';

import Overview from './overview/Overview';
import Footer from './Footer';

import { Switch, Route, Redirect, withRouter } from 'react-router-dom'

import { connect} from 'react-redux';

const mapStateToProps = state => {
    return {
      actions:state.actions,
      footer:state.footer,
      header: state.header,
      user: state.user,
      notifications: state.notifications
    }
  }


class Main extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show_subheader: true,
    };
  }


  render() {
    return(
    <React.Fragment>
      <header className="header dark" > 
          <NavBarTop user={this.props.user} content={this.props.header} notifications={this.props.notifications} className="container-fluid" />
      </header>
      <div id="mainWrapper" className="wrapper pl-0 pr-0 main">
          <div id="mainSidebarDiv" className="col-2">
            <NavBarSide items={this.props.actions} className="medium"/>
          </div>
          <div id="mainContainerDiv" className="pt-0 main-container">
            <NavBarSubHeader show={this.state.show_subheader}/>
            <div className="col-12 main-container">
                <Switch>
                  <Route exact path='/projects' component={() => <Browser user={this.props.user} notifications={this.props.notifications}/>} />
                  <Route exact path='/overview' component={Overview} />
                  <Redirect to="/projects" />
                </Switch>
          </div>
        </div>
      </div>
      <Footer content={this.props.footer}/>
      </React.Fragment>
      );
    }
  }

export default withRouter(connect(mapStateToProps)(Main));