import React from 'react';
import { Navbar, Nav, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';


const NavBarSubHeader = (props) => {
const status = props.show ? "" : "hidden";
return(
        <div id="subHeader" className="wrapper">
		  <Navbar id="sidebarToggleDiv" className="d-none d-sm-block light" dark>
		    <span id="sideBarToggler" className="middle fa fa-compress"/>
		  </Navbar>
		  <Navbar className={"medium full-width p-0 "+status} dark>
		  <Nav navbar >
		    <NavItem>
		      <NavLink className="nav-link"  to='/projects'><h6>projects</h6></NavLink>
		    </NavItem>
		    <NavItem className="nav-link"><span className="fa fa-chevron-right fa-md"/></NavItem>
		    <NavItem>
		      <NavLink className="nav-link"  to='/overview'><h6>overview</h6></NavLink>
		    </NavItem>
		    <NavItem className="nav-link"><span className="fa fa-chevron-right fa-md"/></NavItem>
		    <NavItem>
		      <NavLink className="nav-link"  to='/overview'><h6>components</h6></NavLink>
		    </NavItem>
		  </Nav>
		  </Navbar>
		</div> 
    );
}

export default NavBarSubHeader;