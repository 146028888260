export const USER = {
	id: 0,
	name:'Marco',
	thumbnail: 'assets/images/user.png',
	created: "2012-10-16T17:57:28.556094Z",
	modified: "2012-10-16T17:57:28.556094Z",
	license:{
		type: "free",
		expirationDate: "unlimited",
	}
}
