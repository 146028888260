 import React from 'react';
 import {DropdownItem } from 'reactstrap';

 const NotificationItems = (props) => {
  var list = null;
  var notifications
  if ("notifications" in props){
    notifications = props.notifications
      list = notifications.map((not) => {
          var icon = "icon" in not ? not.icon : "";
          var classname = "fa "+icon+" text-yellow";
          icon = (<i className={classname}></i>);
          return (
            <React.Fragment key={not.id}>
            <DropdownItem divider/>
            <DropdownItem >
            {icon}{not.text}
            </DropdownItem>
            </React.Fragment>
            );
      });
  } else if ("messages" in props){
    notifications = props.messages
      list = notifications.map((not) => {
          var icon = "icon" in not ? not.icon : "";
          var classname = "fa "+icon+" text-yellow";
          icon = (<i className={classname}></i>);
          return (
            <React.Fragment key={not.id}>
            <DropdownItem divider/>
            <DropdownItem >
              <h6>{not.sender}&nbsp;<small>{icon}&nbsp;{not.hoursAgo}&nbsp;{"h"}</small></h6>
              {not.text}
            </DropdownItem>
            </React.Fragment>
            );
      });
    }
  return(
    <React.Fragment>
    {list}
    </React.Fragment>
    );
}

export default NotificationItems;