import React, { Component } from 'react';
import Menu from './NavBarMenu';


class NavBarSide extends Component {


  constructor(props) {
    super(props);
    var menu_states = {}
    for (let item in props.items) {
      menu_states[item.id] = false;
    }
    this.state = {
      items: props.items,
      menu_states: menu_states
    };
    this.onToggleMenu = this.onToggleMenu.bind(this);
  }

  onToggleMenu(id){
    var newMenusState = {};
    for (let id in this.state.menu_states) {
      newMenusState[id] = false;
    }
    newMenusState[id] = !this.state.menu_states[id];
    this.setState({ menu_states: newMenusState });
  }

	render(){

		return(
  		<React.Fragment>
  		<aside>
        <div id="mainSidebar" className="sidebar left ">
          <h4 className="center dark pt-2">Actions</h4>
          <hr/>
          <ul className="list-sidebar bg-default">
            <Menu
              items={this.state.items}
              menu_states={this.state.menu_states}
              onClick={(itemId) => this.onToggleMenu(itemId)}
            />
          </ul>
        </div>
      </aside>
      </React.Fragment>
    );
	}
}

export default NavBarSide;