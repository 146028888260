export const PROJECTS =
    [{
        id: 0,
        name:'Off Grid',
        label:'Off Grid',
        image: 'assets/images/offgrid.jpg',
        created: "2012-10-16T17:57:28.556094Z",
        modified: "2012-10-16T17:57:28.556094Z",
        resume: false,
        links: [{
            id:0,
            link:'projects/0',
            label: "Open project"
        },
        {
            id:1,
            link:'projects/0/export',
            label: "Export"
        },
        {
            id:2,
            link:'projects/0/results',
            label: "Jump to results",
        }],
        details: {
            description:'An off-grid hybrid system solution is designed for a rural village in Africa region consisting of 200 homes.\n The hybrid system consisted of three 25kW wind turbines, 300kWp ground-mounted PV system, a 250kWh battery bank to provide 1-day reserve electricity, and a 500kW capacity diesel generator. The system is designed to carry the load of houses, stores and hospital.\n Access to clean electricity improves the people’s health and reduces greenhouse gas emissions.',
            author: 'Zubair Aslam'
        }
    },
    {
        id: 1,
        name:'Rooftop PV',
        label:'Rooftop PV',
        image: 'assets/images/rooftop.jpg',
        created: "2012-10-16T17:57:28.556094Z",
        modified: "2012-10-16T17:57:28.556094Z",
        resume: true,
        links: [{
            id:0,
            link:'projects/1',
            label: "Open project"
        },
        {
            id:1,
            link:'projects/1/export',
            label: "Export"
        },
        {
            id:2,
            link:'projects/1/results',
            label: "Jump to results",
        }],
        details: {
            description: 'A 20kW system with a storage capcity 30 kWh is designed for a commercial building in Europe (Berlin) and ensuring the best return on investment for every Euro spent',
            author: 'Zubair Aslam'
        }
    }];