import React from 'react';
import { Table } from 'reactstrap';
import { parseDate} from '../../util/parseDate'

function UserDetails ({user}){

    const license = user.license    
    return (
        <Table responsive borderless size="sm" > 
            <thead className="">
                <tr><th colSpan="2">Account information</th></tr>
            </thead> 
            <tbody className="">    
                <tr>
                     <th scope="row">Name</th>
                     <td>{user.name}</td>
                </tr>
                <tr><th colSpan="2" scope="col">License</th></tr>
                <tr>
                     <th scope="row">Type</th>
                     <td>{license.type}</td>
                </tr>
                <tr>
                     <th scope="row">Expiration</th>
                     <td>{isNaN(Date.parse(license.expirationDate)) ? license.expirationDate : parseDate(license.expirationDate)}</td>
                </tr>
            </tbody>
        </Table>
    );
}

export default UserDetails;