import React from "react";
import { NavLink } from 'react-router-dom';
import { NavItem } from 'reactstrap';



function RenderMenu ({menu, visible, onClick}) {

  var show = (visible) ? "" : "collapse" ;
  var collapsed = (visible) ? "" : "collapsed" ;
  var rotated = (visible) ? "rotated" : "" ;
  var list = null;
  const mainLink = menu.link==null ? "#" : menu.link
  if (menu.subitems!=null) { 
    list = menu.subitems.map((item) => {
      const subLink = (item.link==null) ? "#" : item.link;
      return(
        <li key={item.id}><a href={subLink}>{item.label}</a></li>
      );
    }); 
  }
  return (
    <React.Fragment>
      <NavLink to={mainLink} onClick={onClick} className={"active " +collapsed }>
        <i className={"fa "+menu.icon}></i>
        <span className="nav-label">{menu.label}</span>
        {list!=null ?
          <span className={"fa fa-chevron-left pull-right "+rotated}></span>
          : null
        }
      </NavLink>
        {list!=null ? 
            <ul  className={"sub-menu " +show } id="tables" >
              {list}    
            </ul>
        : null}
    </React.Fragment>
  );
}

const Menu = (props) => {

  const menus = props.items.map((item) => {
    var visible = props.menu_states[item.id];
    return(
      <NavItem key={item.id}>
        <RenderMenu menu={item} visible={visible} onClick={() => props.onClick(item.id)} />
      </NavItem>
    );
  });

  return(
    <React.Fragment>
      {menus}
    </React.Fragment>
    );
}
export default Menu;