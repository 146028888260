export function parseDate(date){
    return new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short', day: '2-digit'}).format(new Date(Date.parse(date)))
}

export function diffFromNow_inDays(date){
	var second = new Date(date);
	var today = new Date();
    return Math.round((today-second)/(1000*60*60*24));
}

export function diffFromNow_inHours(date){
	var second = new Date(date);
	var today = new Date();
    return Math.round((today-second)/(1000*60*60));
}