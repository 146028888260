import React from 'react';

function Overview(props) {
    return(
    	<React.Fragment>
    	<div class="row">
    		<div class="col-12">
    			<h4>Overview</h4>
    		</div>
    	</div>
    	</React.Fragment>
    );
}

export default Overview; 