import React from 'react';
import { Table } from 'reactstrap';
import {diffFromNow_inDays, parseDate} from '../../util/parseDate'

function NewsBox ({notifications}){

    var list;
    
    if ("items" in notifications){
        const items = notifications.items.filter((not) => diffFromNow_inDays(not.date) < notifications.options.showNotOlderThanDays)
        list = items.map((not) => {
            var icon = "icon" in not ? not.icon : "";
            var classname = "fa "+icon+" text-yellow";
            icon = (<i className={classname}></i>);
            return (
                <tr key={not.id}>
                    <td>{parseDate(not.date)}</td>
                    <td>{not.text}</td>
                    <td><a href={not.link} className="right">&nbsp;information here</a></td>
                </tr>
              
              );
      });
    }
    return (
        <Table responsive borderless size="sm"> 
            <thead className="panel-heading center">
                <tr><th colSpan="3">News for you</th></tr>
            </thead> 
            <tbody className="panel-body">    
                {list}
            </tbody>
        </Table>
    );
}

export default NewsBox;