import React, { Component } from 'react';
import { Nav, Navbar, NavbarToggler, Collapse, NavItem } from 'reactstrap';
import GeneralDropdown from '../menus/GeneralDropdown';
import {DropdownItem } from 'reactstrap';
import HeaderButtons from './HeaderButtonsComponent';
import NotificationItems from '../menus/NotificationItem';
import {diffFromNow_inHours} from '../../util/parseDate';

class NavBarTop extends Component {


  constructor(props) {
    super(props);

    this.toggleNav = this.toggleNav.bind(this);
    this.state = {
      isNavOpen: false,
      user: props.user,
      notifications: props.notifications,
      content: props.content,
    };
  }

  toggleNav() {
    this.setState({
      isNavOpen: !this.state.isNavOpen
    });
  }

  
	render(){
    const user = this.state.user;
    const nots = this.state.notifications.notifications;
    const nots_items = nots.items.filter((not) => diffFromNow_inHours(not.date) < nots.options.showNotOlderThanHours)
    const messages = this.state.notifications.messages;
    const msg_items = messages.items.filter((not) => diffFromNow_inHours(not.date) < messages.options.showNotOlderThanHours)
    const header_buttons = this.state.content.items.filter((item) => item.show)

		return(
      <Navbar dark expand="md" className="row" >
      
        
        <Nav className="col-md-2 col-6" href="/">
          <NavItem>
            <img src='assets/images/elogo.png' height="50" alt='MgC' />
          </NavItem>
        </Nav>
        <div className="col-3 d-md-none right" > <NavbarToggler onClick={this.toggleNav} /> </div>
        <Collapse isOpen={this.state.isNavOpen} navbar >
            <div className="col-md-9 m-0 p-0">
            <Nav navbar >
              <HeaderButtons items={header_buttons} />
            </Nav>
            </div>
            <div className="col-md-3 m-0 p-0">
            <Nav id="userDiv" className="user-div" navbar>
              <NavItem className="user-panel">
                <GeneralDropdown caret={true} classname="nav-link" placeholder={
                  <React.Fragment>
                    <img src={user.thumbnail} alt="user" className="user-image"/>
                    <span className="hidden-xs">{user.name}</span>
                  </React.Fragment> 
                } elements={
                  <React.Fragment> 
                    <DropdownItem disabled>Log in</DropdownItem>
                    <DropdownItem>Log out</DropdownItem>
                    <DropdownItem divider/>
                    <DropdownItem header>Settings</DropdownItem>
                    <DropdownItem>General</DropdownItem>
                    <DropdownItem>Account</DropdownItem>
                  </React.Fragment> 
              }/>
              </NavItem>
              <NavItem>
                <GeneralDropdown classname="nav-link" placeholder={
                  <React.Fragment>
                    <i className={"fa "+nots.icon}></i>
                    {nots_items.length>0 ? <span className="bg-warning badge">{nots_items.length}</span>: null}
                  </React.Fragment> 
                } elements={
                  <React.Fragment> 
                  <DropdownItem header>{nots_items.length?nots.title:nots.ifEmpty}</DropdownItem>
                    <NotificationItems notifications={nots_items}/>
                    <DropdownItem header tag="a" href={nots.link} className="light">{nots.linkText}</DropdownItem>
                  </React.Fragment> 
              }/>
              </NavItem>
              <NavItem>
              <GeneralDropdown classname="nav-link" placeholder={
                  <React.Fragment>
                    <i className={"fa "+messages.icon}></i>
                    {msg_items.length>0 ? <span className="bg-warning badge">{msg_items.length}</span>: null}
                  </React.Fragment> 
                } elements={
                  <React.Fragment> 
                    <DropdownItem header>{msg_items.length?messages.title:messages.ifEmpty}</DropdownItem>
                    <NotificationItems messages={msg_items}/>
                    <DropdownItem header><a className="light" href={messages.link}>{messages.linkText}</a></DropdownItem>
                  </React.Fragment> 
              }/>
              </NavItem>
            
          </Nav>
          </div>
          </Collapse>
        </Navbar>
        );
	}
}

export default NavBarTop;