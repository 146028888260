import React, { Component } from 'react';
import { PROJECTS } from '../../shared/projects';
import ProjectDetails from './ProjectDetails.jsx';
import NewsBox from './NewsBoxComponent';
import UserDetails from './UserDetailsComponent';

class Browser extends Component {

	constructor(props) {
		super(props);
		var prj = PROJECTS.filter((project) => project.resume === true)[0];
        this.state = {
            projects: PROJECTS,
            user: props.user,
            notifications: props.notifications.notifications,
            selectedProjectId: prj.id
        }
    }

    onProjectSelect(project) {
        this.setState({ selectedProjectId: project});
    }

    render(){
    	let prj = this.state.projects.filter((project) => project.id === this.state.selectedProjectId)[0]
	  	return (
            <React.Fragment>
                <div className="row mt-4">
                	{prj!=null ? <ProjectDetails project={prj} /> : null }
                </div>

                <div className="row mt-4 mb-4">
                    <div className="col-md-1"></div>
                    <div className="col-md-6">
                        <NewsBox notifications={this.state.notifications}/>
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-2">
                        <UserDetails user={this.state.user}/>
                    </div>
                </div>

         
            </React.Fragment>
	    );
	}
}

export default Browser;