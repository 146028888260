export const HEADER ={
	title:"hi",
	items: [{
		id: 0,
		icon:"fa-life-ring",
		label: "Support",
		link: "#",
		show: true,
		disable:false,
	},{
		id: 1,
		icon:"fa-save",
		label: "Save",
		link: "#",
		show: true,
		disable: false,
	}
	]
};