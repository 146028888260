import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

export default  function GeneralDropdown ({placeholder,elements,classname,caret}) {
  var class_name = classname ? classname : "nav-link";
  return (
    <React.Fragment>
    <UncontrolledDropdown modifiers={{
      setMaxHeight: {
        enabled: true,
        order: 890,
        fn: (data) => {
          return {
            ...data,
            styles: {
              ...data.styles,
              overflow: 'scroll',
              maxHeight: 100,
              maxWidth: 10
            },
          };
        },
      },
    }} tag="div" className={class_name} >
      <DropdownToggle tag="span" className={caret!=null ? "dropdown-toggle" : ""}>
        {placeholder}
      </DropdownToggle>
      <DropdownMenu className="dropdown-general">
        {elements}
      </DropdownMenu>
    </UncontrolledDropdown>
    </React.Fragment>
  );
}