import React from 'react';
// import {parseDate} from '../util/parseDate'
import { Media, Button } from 'reactstrap';

// import { Card, CardImg, CardText, CardBody,CardTitle } from 'reactstrap';

function RenderProjectDetails ({project}) {
    return (
        <Media>
            <Media className="col-3" href="#">
                  <Media object className="img-rounded" src={project.image} alt={project.name}/>
            </Media>
            <Media body className="col-9 text-ellipsis">
                <Media heading >{project.name}</Media>
                <p>{project.details.description}</p>
                <div className="right" >
                  <RenderLinks project={project} />
                </div>
            </Media>
        </Media>

    );
}

function RenderLinks({project}){
  const list = project.links.map((link) => {
    return(
      <Button key={link.id} color="primary" href={link.link}>{link.label}</Button>
    );
  });
  return(
    <React.Fragment>
    {list}
    </React.Fragment>
  );
}

const ProjectDetails = (props) => {

	return(
		<React.Fragment>
        <div className="col-md-2"></div>
		<div className="col-md-8">
		  <RenderProjectDetails project={props.project}/>
		</div> 
		{/*<div  className="col-6"> 
			<h3>Details</h3><br/>
			<RenderDetails project={props.project}/>
		</div>*/}
		</React.Fragment>
    );
}

export default ProjectDetails;

// function RenderDetails({project}) {
  
   // const details = project.details.map((detail) => {
   //          return (
   //          <div  className="row" key={}>
   //              <ul>
   //              <li>{comment.comment}</li>
   //              <p>{comment.author}, {parseDate(comment.date)}</p>
   //              </ul>
   //          </div>
   //          );
   //      });
//     return (
//         <div  className="row">
//             <ul>
//             <p>{project.details.author}</p>
//             <p>{project.details.description}</p>
//             </ul>
//         </div>
//     );
// }




    // renderProjectComments(project) {
    // 	if (project != null){
	       
	   //  } else {
    //         return(
    //             <div></div>
    //         );
    //     }
    // }
