export const NOTIFICATIONS ={
	notifications:{
		options:{
			showNotOlderThanHours:72,
			showNotOlderThanDays:30
		},
		id: 0,
		title: "Notifications",
		ifEmpty:"No notification",
		icon: "fa-bell-o",
		link: "#",
		linkText: "Show all",
		items: [{
				id: 0,
				date: "2018-11-22T17:57:28.556094Z",
				icon:"fa-warning",
				text: "Your last simulation has completed",
				link: "#"
			},{
				id: 1,
				date: "2018-11-15T17:57:28.556094Z",
				icon:"fa-warning",
				text: "Version 1.1.2 released !!",
				link: "#"
			},{
				id: 2,
				date: "2018-11-10T17:57:28.556094Z",
				icon:"fa-warning",
				text: "Welcome to the MgC",
				link: "#"
			}
		]
	},
	messages:{
		options:{
			showNotOlderThanHours:168
		},
		id: 0,
		title: "Messages",
		ifEmpty:"No message",
		icon: "fa-envelope-o",
		link: "#",
		linkText: "Show all",
		items: [{
				id: 0,
				date: "2018-11-20T17:57:28.556094Z",
				icon:"fa-clock-o",
				text: "Whats up?",
				sender: "Admin",
				hoursAgo:"1",
				link: "#"
			},{
				id: 1,
				date: "2018-11-20T17:57:28.556094Z",
				icon:"fa-clock-o",
				text: "Whats up?",
				sender: "Admin",
				hoursAgo:"2",
				link: "#"
			}
		]
	},
	messages1:{
		id: 0,
		ifEmpty:"No message",
		title: "Messages",
		icon: "fa-envelope-o",
		link: "#",
		items: []
	},
	notifications1:{
		id: 0,
		title: "Notifications",
		ifEmpty:"No notification",
		icon: "fa-bell-o",
		link: "#",
		items: []
	},
};