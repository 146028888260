export const FOOTER=[
	{
		"id":0,
		"label":" energenious.eu",
		"link":"https://energenious.eu"
	},
	{
		"id":1,
		"label":" © Copyright 2018 MicroGridCreator"
	},
	{
		"id":2,
		"label":" suggest features!",
		"link":"mailto:innovation@energenious.eu"
	}];