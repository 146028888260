export const ITEMS =
	[{
		id: 0,
		name: "newProject",
		label: "New Project",
		icon: "fa-plus",
		link: "#",
		subitems: [{
				id: 0,
				label: "from existing project",
				link: "/overview"
			},{
				id: 1,
				label: "from default"
			},{
				id: 2,
				label: "from empty project"
			},{
				id: 3,
				label: "import"
			}
		]},
	{
		id: 1,
		name: "selectedProject",
		label: "Open Project",
		icon: "fa-folder-open",
		subitems: [
			{
				id: 0,
				label: "Berlin rooftop",
				state: 0
			},
			{
				id: 1,
				label: "Mubayira offgrid",
				state:1
			}
		]
	}];