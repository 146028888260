import {USER} from '../shared/user';
import {FOOTER} from '../shared/footer';
import {ITEMS} from '../shared/action_items';
import {HEADER} from '../shared/header';
import {NOTIFICATIONS} from '../shared/notifications.js';

export const initialState = {
    actions:ITEMS,
    footer:FOOTER,
    header: HEADER,
    user: USER,
    notifications: NOTIFICATIONS
};

export const Reducer = (state = initialState, action) => {
    return state;
};